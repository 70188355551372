<template>
    <div class="packages">
    <div class="cards">
        <div id="tariftable">
        <h2 class="tarif-title">
        {{ translation.translate("Price", "tarifs") }}
      </h2>
        <div class="table table-responsive">
            <table id="customers">
                <tr>
                    <td></td>
                    <td class="tarif-head-title">
                        <h4>Оператор Free</h4>
                        <h6>{{ translation.translate("Price", "freePeriod") }} </h6>
                        <p id="number">0</p>
                        <p id="sum">{{ translation.translate("Price", "som") }}/{{ translation.translate("Price", "month") }}</p>
                        <a ></a>
                        <input class="aktivirovat tarif_free mt-3" type=button onClick="location.href='http://app.faktura.uz/tariff/index'" :value=" translation.translate('Price', 'activate')">
                    </td>
                    <td  class="tarif-head-title">
                        <h4>{{ translation.translate("Price", "EDO") }} 100</h4>
                        <h6>{{ translation.translate("Price", "basicTariff") }}</h6>
                        <p id="number">55 000</p>
                        <p id="sum">{{ translation.translate("Price", "som") }}/{{ translation.translate("Price", "month") }}</p>
                        <input class="aktivirovat tarif_edo_100 mt-3" type=button onClick="location.href='http://app.faktura.uz/tariff/index'" :value=" translation.translate('Price', 'activate')">
                    </td>
                    <td  class="tarif-head-title">
                        <h4>{{ translation.translate("Price", "EDO") }} 250</h4>
                        <h6>{{ translation.translate("Price", "microBusiness") }}</h6>
                        <p id="number">240 000</p>
                        <p id="sum">{{ translation.translate("Price", "som") }}/{{ translation.translate("Price", "month") }}</p>
                        <input class="aktivirovat tarif_edo_250 mt-3" type=button onClick="location.href='http://app.faktura.uz/tariff/index'" :value=" translation.translate('Price', 'activate')">
                    </td>
                    <td  class="tarif-head-title">
                        <h4>{{ translation.translate("Price", "EDO") }} 1000</h4>
                        <h6>{{ translation.translate("Price", "littleBusiness") }}</h6>
                        <p id="number">790 000</p>
                        <p id="sum">{{ translation.translate("Price", "som") }}/{{ translation.translate("Price", "month") }}</p>
                        <input class="aktivirovat tarif_edo_1000 mt-3" type=button onClick="location.href='http://app.faktura.uz/tariff/index'" :value=" translation.translate('Price', 'activate')">                 
                    </td>
                    <td  class="tarif-head-title">
                        <h4>{{ translation.translate("Price", "EDO") }} 2500</h4>
                        <h6>{{ translation.translate("Price", "middleBusiness") }}</h6>
                        <p id="number">1 690 000</p>
                        <p id="sum">{{ translation.translate("Price", "som") }}/{{ translation.translate("Price", "month") }}</p>
                        <input class="aktivirovat tarif_edo_2500 mt-3" type=button onClick="location.href='http://app.faktura.uz/tariff/index'" :value=" translation.translate('Price', 'activate')">                                 
                    </td>
                    <td  class="tarif-head-title">
                        <h4>{{ translation.translate("Price", "EDO") }} 5000</h4>
                        <h6>{{ translation.translate("Price", "bigBusiness") }}</h6>
                        <p id="number">3 140 000</p>
                        <p id="sum">{{ translation.translate("Price", "som") }}/{{ translation.translate("Price", "month") }}</p>
                        <input class="aktivirovat tarif_edo_5000 mt-3" type=button onClick="location.href='http://app.faktura.uz/tariff/index'" :value=" translation.translate('Price', 'activate')">                 
                    </td>
                </tr>
                <tr>
                    <td id="service">
                        {{ translation.translate("Price", "users") }}
                    </td>
                    <td>{{ countDocsTextName(3)}}</td>
                    <td>{{ countDocsTextName(3)}}</td>
                    <td>{{ countDocsTextName(5)}}</td>
                    <td>{{ countDocsTextName(20)}}</td>
                    <td>{{ translation.translate("Price", "infinite") }}</td>
                    <td>{{ translation.translate("Price", "infinite") }}</td>
                </tr>
                <tr>
                    <td id="service">
                        {{ translation.translate("Price", "outcomeDocs") }}
                    </td>
                    <td>{{ translation.translate("Price", "accordingPackages") }}</td>
                    <td>{{ countDocsTextName(100)}}</td>
                    <td>{{ countDocsTextName(250)}}</td>
                    <td>{{ countDocsTextName(1000)}}</td>
                    <td>{{ countDocsTextName(2500)}}</td>
                    <td>{{ countDocsTextName(5000)}}</td>
                </tr>
                <tr>
                    <td id="service">
                        {{ translation.translate("Price", "contractors") }}
                    </td>
                    <td>{{ countDocsTextName(250) }}</td>
                    <td>{{ countDocsTextName(250) }}</td>
                    <td>{{ countDocsTextName(250)}}</td>
                    <td>{{ translation.translate("Price", "infinite") }}</td>
                    <td>{{ translation.translate("Price", "infinite") }}</td>
                    <td>{{ translation.translate("Price", "infinite") }}</td>
                </tr>       
            </table>
        </div>
       <div class="table-spoiler">
            <p colspan="6" @click="changeVisibility()">{{ changeHideShowText }}</p>
       </div>
                   
                
        <div v-if="seen" class="table">
            <table id="customers">
                <tr class="title">
                    <td colspan="7">
                        {{ translation.translate("Price", "docs") }}
                    </td>
                </tr>
                <tr>
                    <td></td>
                    <td class="tarif-second-head-title">
                        <h3>Оператор Free</h3>
                    </td>
                    <td class="tarif-second-head-title">
                        <h3>ЭДО 100</h3>
                    </td>
                    <td class="tarif-second-head-title">
                        <h3>ЭДО 250</h3>
                    </td>
                    <td class="tarif-second-head-title">
                        <h3>ЭДО 1000</h3>
                    </td>
                    <td class="tarif-second-head-title">
                        <h3>ЭДО 2500</h3>
                    </td>
                    <td class="tarif-second-head-title">
                        <h3>ЭДО 5000</h3>
                    </td>
                </tr>
                <tr>
                    <td id="service">
                        {{ translation.translate("Price", "roamingDocs") }}
                    </td>
                    <td>&#10004;</td>
                    <td>&#10004;</td>
                    <td>&#10004;</td>
                    <td>&#10004;</td>
                    <td>&#10004;</td>
                    <td>&#10004;</td>
                </tr>
                <tr>
                    <td id="service">
                        {{ translation.translate("Price", "dynamicDoc") }}
                    </td>
                    <td>&#10004;</td>
                    <td>&#10004;</td>
                    <td>&#10004;</td>
                    <td>&#10004;</td>
                    <td>&#10004;</td>
                    <td>&#10004;</td>
                </tr>
                <tr>
                    <td id="service">
                    {{ translation.translate("Price", "informalDoc") }}
                    </td>
                    <td>&#10004;</td>
                    <td>&#10004;</td>
                    <td>&#10004;</td>
                    <td>&#10004;</td>
                    <td>&#10004;</td>
                    <td>&#10004;</td>
                </tr>
                <tr>
                    <td id="service">
                        {{ translation.translate("Price", "publicDoc") }}
                    </td>
                    <td>&#10004;</td>
                    <td>&#8722;</td>
                    <td>&#10004;</td>
                    <td>&#10004;</td>
                    <td>&#10004;</td>
                    <td>&#10004;</td>
                </tr>
                <tr>
                    <td id="service">
                        {{ translation.translate("Price", "reestrimport") }}
                    </td>
                    <td>&#10004;</td>
                    <td>&#10004;</td>
                    <td>&#10004;</td>
                    <td>&#10004;</td>
                    <td>&#10004;</td>
                    <td>&#10004;</td>
                </tr>
                <tr>
                    <td id="service">
                    {{ translation.translate("Price", "individualFormatInformation") }}
                    </td>
                    <td>&#10004;</td>
                    <td>&#10004;</td>
                    <td>&#10004;</td>
                    <td>&#10004;</td>
                    <td>&#10004;</td>
                    <td>&#10004;</td>
                </tr>
                <tr>
                    <td id="service">
                        {{ translation.translate("Price", "dynamictemplatePreview") }}
                    </td>
                    <td>&#8722;</td>
                    <td>&#8722;</td>
                    <td>&#10004;</td>
                    <td>&#10004;</td>
                    <td>&#10004;</td>
                    <td>&#10004;</td>
                </tr>
                <tr>
                    <td id="service">
                    {{ translation.translate("Price", "bulkSigning") }}
                    </td>
                    <td>&#10004;</td>
                    <td>&#10004;</td>
                    <td>&#10004;</td>
                    <td>&#10004;</td>
                    <td>&#10004;</td>
                    <td>&#10004;</td>
                </tr>
                <tr>
                    <td id="service">
                        {{ translation.translate("Price", "routingDocs") }}
                    </td>
                    <td>&#8722;</td>
                    <td>&#8722;</td>
                    <td>&#8722;</td>
                    <td>&#10004;</td>
                    <td>&#10004;</td>
                    <td>&#10004;</td>
                </tr>
                <tr>
                    <td id="service">
                        {{ translation.translate("Price", "metka") }}
                    </td>
                    <td>&#8722;</td>
                    <td>&#8722;</td>
                    <td>&#8722;</td>
                    <td>&#10004;</td>
                    <td>&#10004;</td>
                    <td>&#10004;</td>
                </tr>
                <tr>
                    <td id="service">
                        {{ translation.translate("Price", "checkEDocsZip") }}
                    </td>
                    <td>&#10004;</td>
                    <td>&#10004;</td>
                    <td>&#10004;</td>
                    <td>&#10004;</td>
                    <td>&#10004;</td>
                    <td>&#10004;</td>
                </tr>
                <tr>
                    <td id="service">
                        {{ translation.translate("Price", "filterDocs") }}
                    </td>
                    <td>&#10004;</td>
                    <td>&#10004;</td>
                    <td>&#10004;</td>
                    <td>&#10004;</td>
                    <td>&#10004;</td>
                    <td>&#10004;</td>
                </tr>
                <tr>
                    <td id="service">
                        {{ translation.translate("Price", "historyDocs") }}
                    </td>
                    <td>&#10004;</td>
                    <td>&#10004;</td>
                    <td>&#10004;</td>
                    <td>&#10004;</td>
                    <td>&#10004;</td>
                    <td>&#10004;</td>
                </tr>
                <tr>
                    <td id="service">
                        {{ translation.translate("Price", "protocolByDoc") }}
                    </td>
                    <td>&#8722;</td>
                    <td>&#8722;</td>
                    <td>&#8722;</td>
                    <td>&#10004;</td>
                    <td>&#10004;</td>
                    <td>&#10004;</td>
                </tr>
                <tr>
                    <td id="service">
                        {{ translation.translate("Price", "storageLimit") }}
                    </td>
                    <td>&#8722;</td>
                    <td>&#8722;</td>
                    <td>&#8722;</td>
                    <td>&#10004;</td>
                    <td>&#10004;</td>
                    <td>&#10004;</td>
                </tr>
                <tr>
                    <td id="service">
                        {{ translation.translate("Price", "exportExcel") }}
                    </td>
                    <td>&#10004;</td>
                    <td>&#10004;</td>
                    <td>&#10004;</td>
                    <td>&#10004;</td>
                    <td>&#10004;</td>
                    <td>&#10004;</td>
                </tr>
                <tr>
                    <td id="service">
                        {{ translation.translate("Price", "attachment") }}
                    </td>
                    <td>&#8722;</td>
                    <td>&#10004;</td>
                    <td>&#10004;</td>
                    <td>&#10004;</td>
                    <td>&#10004;</td>
                    <td>&#10004;</td>
                </tr>
                <tr class="title">
                    <td colspan="7">
                        {{ translation.translate("Price", "agreement") }}
                    </td>
                </tr>
                <tr>
                    <td id="service">
                        {{ translation.translate("Price", "stepByStepApprove") }}
                    </td>
                    <td>&#8722;</td>
                    <td>&#8722;</td>
                    <td>&#8722;</td>
                    <td>&#10004;</td>
                    <td>&#10004;</td>
                    <td>&#10004;</td>
                </tr>
                <tr>
                    <td id="service">
                        {{ translation.translate("Price", "approveWithERI") }}
                    </td>
                    <td>&#8722;</td>
                    <td>&#8722;</td>
                    <td>&#8722;</td>
                    <td>&#10004;</td>
                    <td>&#10004;</td>
                    <td>&#10004;</td>
                </tr>
                <tr>
                    <td id="service">
                        {{ translation.translate("Price", "approveWithoutERI") }}
                    </td>
                    <td>&#8722;</td>
                    <td>&#8722;</td>
                    <td>&#8722;</td>
                    <td>&#10004;</td>
                    <td>&#10004;</td>
                    <td>&#10004;</td>
                </tr>
                <tr>
                    <td id="service">
                        {{ translation.translate("Price", "sign") }}
                    </td>
                    <td>&#8722;</td>
                    <td>&#8722;</td>
                    <td>&#10004;</td>
                    <td>&#10004;</td>
                    <td>&#10004;</td>
                    <td>&#10004;</td>
                </tr>
                <tr>
                    <td id="service">
                        {{ translation.translate("Price", "autoSendForApprove") }}
                    </td>
                    <td>&#8722;</td>
                    <td>&#8722;</td>
                    <td>&#8722;</td>
                    <td>&#8722;</td>
                    <td>&#10004;</td>
                    <td>&#10004;</td>
                </tr>
                <tr class="title">
                    <td colspan="7">
                        {{ translation.translate("Price", "reports") }}
                    </td>
                </tr>
                <tr>
                    <td id="service">
                    <span  
                    style="text-decoration: underline;"
                    data-toggle="collapse"
                    href="#collapse15"
                    role="button"
                    aria-expanded="false"
                    aria-controls="collapse15"
                    @click="redirectUrl('collapse15-container')">
                        {{ translation.translate("Price", "reportWork") }} 
                    </span> *
                    </td>
                    <td>&#8722;</td>
                    <td>&#8722;</td>
                    <td>&#10004;</td>
                    <td>&#10004;</td>
                    <td>&#10004;</td>
                    <td>&#10004;</td>
                </tr>
                <tr>
                    <td id="service">
                        {{ translation.translate("Price", "preliminaryVATreports") }}
                    </td>
                    <td>&#10004;</td>
                    <td>&#10004;</td>
                    <td>&#10004;</td>
                    <td>&#10004;</td>
                    <td>&#10004;</td>
                    <td>&#10004;</td>
                </tr>
                <tr>
                    <td id="service">
                        {{ translation.translate("Price", "individualReports") }}
                    </td>
                    <td>&#8722;</td>
                    <td>&#8722;</td>
                    <td>&#10004;</td>
                    <td>&#10004;</td>
                    <td>&#10004;</td>
                    <td>&#10004;</td>
                </tr>
                <tr class="title">
                    <td colspan="7">
                        {{ translation.translate("Price", "anotherFunction") }}
                    </td>
                </tr>
                <tr>
                    <td id="service">
                        {{ translation.translate("Price", "divisions") }}
                    </td>
                    <td>&#10004;</td>
                    <td>&#10004;</td>
                    <td>&#10004;</td>
                    <td>&#10004;</td>
                    <td>&#10004;</td>
                    <td>&#10004;</td>
                </tr>
                <tr>
                    <td id="service">
                        {{ translation.translate("Price", "filial") }}
                    </td>
                    <td>&#10004;</td>
                    <td>&#10004;</td>
                    <td>&#10004;</td>
                    <td>&#10004;</td>
                    <td>&#10004;</td>
                    <td>&#10004;</td>
                </tr>
                <tr>
                    <td id="service">
                        {{ translation.translate("Price", "exchangeDocAmongFirm") }}
                    </td>
                    <td>&#10004;</td>
                    <td>&#10004;</td>
                    <td>&#10004;</td>
                    <td>&#10004;</td>
                    <td>&#10004;</td>
                    <td>&#10004;</td>
                </tr>
                <tr>
                    <td id="service">
                        {{ translation.translate("Price", "storageLimitdDocs") }}
                    </td>
                    <td>2</td>
                    <td>2</td>
                    <td>4</td>
                    <td>6</td>
                    <td>8</td>
                    <td>16</td>
                </tr>               
                <tr>
                    <td id="service">
                        {{ translation.translate("Price", "notification") }}
                    </td>
                    <td>&#10004;</td>
                    <td>&#10004;</td>
                    <td>&#10004;</td>
                    <td>&#10004;</td>
                    <td>&#10004;</td>
                    <td>&#10004;</td>
                </tr>
                <tr>
                    <td id="service">
                        {{ translation.translate("Price", "integrateSystem") }}
                    </td>
                    <td>&#8722;</td>
                    <td>&#10004;</td>
                    <td>&#10004;</td>
                    <td>&#10004;</td>
                    <td>&#10004;</td>
                    <td>&#10004;</td>
                </tr>
                <tr>
                    <td id="service">
                        {{ translation.translate("Price", "synchronDocsWithRoaming") }}
                    </td>
                    <td>&#10004;</td>
                    <td>&#10004;</td>
                    <td>&#10004;</td>
                    <td>&#10004;</td>
                    <td>&#10004;</td>
                    <td>&#10004;</td>
                </tr>
                <tr>
                    <td id="service">
                    {{ translation.translate("Price", "countMultiOrganizatins") }}
                    </td>
                    <td>3</td>
                    <td>3</td>
                    <td>5</td>
                    <td>10</td>
                    <td>&infin;</td>
                    <td>&infin;</td>
                </tr>
                <tr>
                    <td id="service">
                        {{ translation.translate("Price", "changeCommunication") }}
                    </td>
                    <td>&#8722;</td>
                    <td>&#8722;</td>
                    <td>&#8722;</td>
                    <td>&#10004;</td>
                    <td>&#10004;</td>
                    <td>&#10004;</td>
                </tr>
                <tr>
                    <td id="service">
                        {{ translation.translate("Price", "hybridPost") }}
                    </td>
                    <td>&#10004;</td>
                    <td>&#10004;</td>
                    <td>&#10004;</td>
                    <td>&#10004;</td>
                    <td>&#10004;</td>
                    <td>&#10004;</td>
                </tr>
                <tr>
                    <td id="service">
                        {{ translation.translate("Price", "KPKTransaction") }}
                    </td>
                    <td>&#10004;</td>
                    <td>&#10004;</td>
                    <td>&#10004;</td>
                    <td>&#10004;</td>
                    <td>&#10004;</td>
                    <td>&#10004;</td>
                </tr>
                <tr class="title">
                    <td colspan="7">
                    {{ translation.translate("Price", "externalModules") }}
                    </td>
                </tr>
                <tr>
                    <td id="service">
                        {{ translation.translate("Price", "1CModule") }}
                    </td>
                    <td>&#10004;</td>
                    <td>&#10004;</td>
                    <td>&#10004;</td>
                    <td>&#10004;</td>
                    <td>&#10004;</td>
                    <td>&#10004;</td>
                </tr>
                <tr>
                    <td id="service">
                        {{ translation.translate("Price", "localElectronArchive") }}
                    </td>
                    <td>&#10004;</td>
                    <td>&#10004;</td>
                    <td>&#10004;</td>
                    <td>&#10004;</td>
                    <td>&#10004;</td>
                    <td>&#10004;</td>
                </tr>
                <tr>
                    <td id="service">
                        {{ translation.translate("Price", "plaginForSyncWithSoliq") }}
                    </td>
                    <td>&#10004;</td>
                    <td>&#10004;</td>
                    <td>&#10004;</td>
                    <td>&#10004;</td>
                    <td>&#10004;</td>
                    <td>&#10004;</td>
                </tr>
            </table>
        </div>
    </div>
    </div>
    </div>
</template>

<script defer>
import translate from "../../translation/translate";

export default {
    name: "tariftable",
    data() {     
        return {
            translation: translate,
            seen: false,
            hideShowText: "Свернуть детальное сравнение", 
            countDocsText: ""
        };
    },
    methods: {
        redirectUrl(hashtag) {
        window.scrollTo(0, 0);
        let position = document.getElementById(hashtag).getBoundingClientRect()
        window.scrollTo({
          top: position.y - 80,
          behavior: 'smooth'
        })
    },
        changeVisibility: function () {
            this.seen = this.seen == false ? true : false;
            this.hideShowText = this.hideShowText == this.translation.translate("Price", "shortDetails") ? this.translation.translate("Price", "inDetails") : this.translation.translate("Price", "shortDetails");
        },
        countDocsTextName: function(count){
            this.countDocsText = this.translation.translate("Price","doDocsCount");
            if (this.countDocsText == "до") { return this.countDocsText +" "+ count; }
            else { return count +" " + this.countDocsText; }
        }
    },
    computed:{
        changeHideShowText: function () {
             return this.hideShowText == this.translation.translate("Price", "shortDetails") ? this.translation.translate("Price", "inDetails") : this.translation.translate("Price", "shortDetails")
        },      
    }
};
</script>

<style>
.table-spoiler {
    width: 100%;
    margin-top: 19px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #45B411;
    text-align: center;
    cursor: pointer;
    background-color: WHITE;
}

#customers {
    width: 100%;
}

#tariftable{
    width: 100%;
}
#customers a{
    color: #45B411;
}
.table {
    background-color: white;
    margin-left: auto;
    margin-right: auto;   
    overflow-x: auto;
    display: block;
}

.tarif-head-title {
    width: 210px;
}
.tarif-second-head-title h3{
        font-size: 16px;
        font-weight: 700;
        vertical-align: center;
        margin-bottom: 0px;
}

#customers h6{
    font-weight: 300;
    font-size: small;
}
#customers td,
#customers tr {
    border: 1px solid #ddd;
    padding: 10px 10px;
    text-align: center;
    font-weight: 500;
    vertical-align: middle;
}

td#service {
    text-align: left;
    padding-left: 10px; 
    font-weight: 600 !important;
    width: 210px;
}

#customers td h4 {
    width: 100%;
    border-bottom: 2px solid #ddd;
    margin-top: 10px;
    padding-bottom: 10px;
    font-weight: 700;
    color: #363636;
}

#customers td #number {
    width: 100%;
    font-weight: 1000;
    font-size: 27px;
    margin-top: 5%;
    margin-bottom: 0px;
    color: #363636;
}

#customers td #sum {
    width: 100%;
    font-weight: 700;
    color: #363636;
}
.aktivirovat :hover{
    color: white !important;
}
#customers td input,
.packages input {
    border: 1px solid #45B411;
    background-color: white;
    color: #45B411;
    font-size: 14px;
    padding: 5px 10px;
    border-radius: 7px;
    font-weight: 600;
}

.table #customers input:hover {
    background-color: #45B411;
    color: white !important;
    transition: 0.5s;
}

td:hover {
    border-left-color: #45B411;
    border-right-color: #45B411;
}

#customers .title {
    background-color: #ebebeb !important;
    font-weight: 600;
}

.packages {
    padding: 30px;
}
.tarif-title{
    padding-bottom: 20px;
}
.packages .card h4,
h5,
h6 {
    width: 80%;
    margin-top: 10px;
    font-weight: 800;
    color: black;
}

.packages .card h6 {
    border-bottom: 2px solid #ddd;
    padding-bottom: 10px;
}

.packages .card h4 {
    padding-top: 10px;
    margin: 0 !important;
    font-weight: 800;
}

.packages .card h6 {
    margin: 0;
    font-weight: 800;
}

.packages input:hover {
    background-color: green;
    color: white;
    transition: 0.5s;
}

.packages input {
    font-weight: 500;
    background-color: #45B411;
    color: white;
    padding: 7px 50px;
    border: none;
    margin-bottom: 0px;
}

.packages .card a {
    font-weight: 600 !important;
    color: black;
}

@media screen and (max-width: 414px) {
    .packages {
        width: 80%;
        padding-left: auto;
        padding-right: auto;
        margin-left: auto;
        margin-right: auto;
    }

    .packages input {
        padding: 8px 60px;
    }
    .table{
        width: 90%;
    }

}
@media screen and (max-width: 850px) {
}
@media screen and (max-width: 1540px) {
    .packages input {
        padding: 3px 20px;
        border: none;
    }
    .packages .card {
        width: 100%;
        text-align: center;
        border-radius: 10px;
    }
}
@media screen and (max-width: 1512px) {
    #customers td #number {
        font-size: 15px;
    }

    #customers td h4 {
        font-size: 15px;
    }

    #customers td #sum{
        font-size: 10px;
    }

}
</style>
